// TODO - this file is for the monitor part, it should be moved to the monitor part

export default class ContactForm {

  constructor() {
    this.bindEvents();
  }

  bindEvents() {
    $("#call-subject-select").on("change", function() {      
      if ($(this).val() == "instructor_need_supplies") {
        $("#supplies-choice").show();
      }else{
        $("#supplies-choice").hide();
      }
    });
  }

  onDataChanged(data) {
      this.planning.onDataChanged(data);
  }

  onDestroy() {
  }
}